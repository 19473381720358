import { API_DATE_FORMAT } from '~/constants/_constants';
import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import type { ApiHandlerWithArgs, ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { transformZodSchemaFn } from '~/plugins/zod/utils';
import { getTypedKeys } from '~/utils/baseUtils';
import { transformFetchListOptionsToQuery } from '~/api/_transformers';
import { type FetchListOptions, PaginationMetaSchema } from '~/api/_types';
import {
    type FetchOrdersResponse,
    FetchOrdersResponseSchema,
    type OrderChangePublishAtFormData,
    type OrderChangeStateFormData,
    type OrderDetail,
    OrderDetailSchema,
    type OrderFormData,
    OrderIciFormData,
    type OrderStudentFormData,
} from './_types';

const apiEndpointPrefix = 'orders';

export const fetchOrdersRequest: ApiHandlerWithArgs<
    FetchListOptions,
    ApiResponseContext<FetchOrdersResponse | null>
> = async (opt) => {
    const url = formatApiUrl(apiEndpointPrefix);
    return api.get(url, {
        transform: transformZodSchemaFn(FetchOrdersResponseSchema, url),
        transformMeta: transformZodSchemaFn(PaginationMetaSchema, url),
        params: transformFetchListOptionsToQuery(opt),
    });
};

export const fetchOrderDetailRequest: ApiHandlerWithData<number, ApiResponseContext<OrderDetail>> = async (id) => {
    const url = formatApiUrl(apiEndpointPrefix, id);
    return api.get(url, { transform: transformZodSchemaFn(OrderDetailSchema, url) });
};

export const postOrderRequest: ApiHandlerWithData<
    OrderFormData,
    ApiResponseContext<OrderDetail | null, OrderFormData>
> = (data) => {
    const url = `programs/${data.InstitutionId}/${data.ProgramId}/order`;
    return api.post(url, transformOrderFormData(data), {
        transform: transformZodSchemaFn(OrderDetailSchema, url),
    });
};

export const postOrderIciRequest: ApiHandlerWithData<
    OrderIciFormData,
    ApiResponseContext<OrderDetail | null, OrderIciFormData>
> = (data) => {
    const url = formatApiUrl(apiEndpointPrefix);
    return api.post(url, transformOrderIciFormData(data), {
        transform: transformZodSchemaFn(OrderDetailSchema, url),
    });
};

export const patchOrderChangeStateRequest: ApiHandlerWithData<
    { id: number; data: OrderChangeStateFormData },
    ApiResponseContext<OrderDetail | null, OrderChangeStateFormData>
> = async ({ id, data }) => {
    const url = formatApiUrl(apiEndpointPrefix, `${id}/state`);
    return api.patch(url, data, {
        transform: transformZodSchemaFn(OrderDetailSchema, url),
    });
};

export const patchOrderChangePublishAtRequest: ApiHandlerWithData<
    { id: number; data: OrderChangePublishAtFormData },
    ApiResponseContext<OrderDetail | null, OrderChangePublishAtFormData>
> = async ({ id, data }) => {
    const url = formatApiUrl(apiEndpointPrefix, `${id}/publish`);
    return api.patch(url, data, {
        transform: transformZodSchemaFn(OrderDetailSchema, url),
    });
};

export const postOrderCertificatesQueueRequest: ApiHandlerWithData<number, ApiResponseContext<null>> = async (id) => {
    const url = formatApiUrl(apiEndpointPrefix, `${id}/queue`);
    return api.post(url);
};

const transformOrderFormData = (data: OrderFormData) => ({
    Competences: data.Competences,
    Notes: data.Notes,
    PaymentType: data.PaymentType,
    RecipientEmail: data.RecipientEmail,
    FinishedAt: data.FinishedAt.toFormat(API_DATE_FORMAT),
    PublishAt: data.PublishAt.toFormat(API_DATE_FORMAT),
    Students: data.Students.map((s) => transformStudentOrderFormData(s)),
    ProgramRevisionId: data.ProgramId,
    InstitutionRevisionId: data.InstitutionId,
});

const transformOrderIciFormData = (data: OrderIciFormData) => ({
    ...data,
    FinishedAt: data.FinishedAt.toFormat(API_DATE_FORMAT),
    PublishAt: data.PublishAt?.toFormat(API_DATE_FORMAT) ?? null,
    Student: {
        ...data.Student,
        Birthday: data.Student.Birthday?.toFormat(API_DATE_FORMAT) ?? null,
    },
});

const transformStudentOrderFormData = (student: OrderStudentFormData) => {
    const data: Record<string, unknown> = {};

    getTypedKeys(student.Data).forEach((x) => {
        data[x] = {
            ...student.Data[x],
            DegreeBefore: student.Data[x].DegreeBefore ?? null,
            DegreeAfter: student.Data[x].DegreeAfter ?? null,
        };
    });

    return {
        Birthday: student.Birthday?.toFormat(API_DATE_FORMAT) ?? null,
        Email: student.Email ?? null,
        Languages: student.Languages,
        Data: data,
    };
};
