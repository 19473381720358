{
    "pages": {
        "main_title": "IES",
        "all": "Page Not Found",
        "access-denied": "Access Denied",
        "index": "Home Page",
        "auth-login": "Login Page",
        "auth-forgot-password": "Forgot Password",
        "auth-set-password-token": "Set Password",
        "auth-change-password-token": "Change Password",
        "profile": "Profile",
        "admin": "Administration",
        "admin-users": "Administration - Users",
        "admin-users-new-user": "Administration - New User",
        "admin-users-id": "Administrace - User Information",
        "admin-email-templates": "Administration - Email templates",
        "admin-email-templates-id": "Administration - Email Template Detail",
        "admin-files": "Administration - Manage files",
        "admin-files-id": "Administration - Manage files",
        "admin-employers": "Administration - Manage employers",
        "admin-employers-id": "Administration - Manage employers",
        "admin-countries": "Administration - Manage countries",
        "admin-languages": "Administration - Manage languages",
        "admin-statistics": "Administration - Statistics",
        "admin-announcements": "Administration - Announcements",
        "admin-announcements-new": "Administration - New announcement",
        "admin-announcements-id": "Administration - Announcement detail",
        "institutions": "Institutions",
        "institutions-new": "New Institution",
        "institutions-id": "Institution Detail",
        "institutions-id-new-program": "New program for institution",
        "programs": "Programs",
        "programs-new": "New program",
        "programs-id": "Program detail",
        "orders": "Orders",
        "orders-new": "New Order for Institution",
        "orders-new-ici": "New Order for Student",
        "orders-id": "Order Detail",
        "certificates": "Certificates",
        "certificates-id": "Certificate detail",
        "certificates-students-id": "Student Detail",
        "print-queue": "Print Queue"
    },
    "navigation": {
        "actions": {
            "backToOverview": "Back to Overview",
            "backToList": "Back to List"
        },
        "home": {
            "title": "Home Page"
        },
        "institutions": {
            "title": "Institutions"
        },
        "programs": {
            "title": "Programs"
        },
        "orders": {
            "title": "Orders"
        },
        "certificates": {
            "title": "Certificates"
        },
        "queue": {
            "title": "Print"
        },
        "admin": {
            "title": "Administration",
            "users": {
                "title": "Users",
                "subtitle": "Manage users"
            },
            "emailTemplates": {
                "title": "Email Templates",
                "subtitle": "Manage email templates"
            },
            "files": {
                "title": "Files",
                "subtitle": "Manage files"
            },
            "employers": {
                "title": "Employers",
                "subtitle": "Manage employers enum"
            },
            "languages": {
                "title": "Languages",
                "subtitle": "Manage languages enum"
            },
            "countries": {
                "title": "Countries",
                "subtitle": "Manage countries enum"
            },
            "statistics": {
                "title": "Statistics",
                "subtitle": "Statistics overview"
            },
            "announcements": {
                "title": "Announcements",
                "subtitle": "Announcements overview"
            }
        }
    },
    "app": {
        "errors": {
            "title": "Error",
            "unavailable": "Application is unavailable, please try again later.",
            "api": "API Error",
            "dashboard": "Go to dashboard",
            "unauthorized": "This action is unauthorized.",
            "session-expired": "Your session has expired, please log in again.",
            "403": {
                "title": "Access Denied",
                "subtitle": "– We apologize –",
                "text": "You do not have access to this page. However, you can click the button below to return to the home page."
            },
            "404": {
                "title": "Not Found",
                "subtitle": "– We apologize –",
                "text": "The page you are looking for does not exist. How you got here is a mystery. However, you can click on the button below to return to the home page."
            }
        },
        "url": "IES-info.com",
        "short-name": "International Education Society Ltd.",
        "copyright": "Copyright ©2024"
    },
    "generic": {
        "boolean": {
            "true": "Yes",
            "false": "No"
        },
        "actions": {
            "remove": "Remove",
            "add": "Add",
            "reset": "Reset",
            "delete": "Delete",
            "activate": "Activate",
            "deactivate": "Deactivate",
            "upload": "Upload",
            "confirm": "Confirm",
            "edit": "Edit",
            "cancel": "Cancel",
            "createRevision": "Create revision",
            "print": "Print"
        },
        "tooltips": {
            "remove": "Remove",
            "edit": "Edit",
            "delete": "Delete",
            "detail": "Detail",
            "copy": "Copy",
            "clear": "Clear",
            "close": "Close",
            "create_revision": "Create revision",
            "menu": "Menu",
            "download": "Download"
        }
    },
    "enums": {
        "userType": {
            "Admin": "Admin",
            "Client": "Client",
            "Approver": "Approver",
            "Student": "Student"
        },
        "institutionType": {
            "ElementarySchool": "Elementary School",
            "HighSchool": "High School",
            "College": "College",
            "EducationAgency": "Education Agency",
            "PostSecondaryVocationalSchool": "Post Secondary Vocational School"
        },
        "institutionState": {
            "InRegistration": "In Registration",
            "Active": "Active",
            "Inactive": "Inactive"
        },
        "programState": {
            "Inactive": "Inactive",
            "WaitingForDocuments": "Waiting for documents",
            "SentForCertification": "Sent for certification",
            "Certified": "Certified",
            "CertificationRefused": "Certification refused"
        },
        "orderPaymentType": {
            "Invoice": "Invoice",
            "PaymentGateway": "Payment Gateway"
        },
        "orderState": {
            "Created": "Created",
            "AwaitingPayment": "Awaiting Payment",
            "Paid": "Paid",
            "Pending": "Pending",
            "Processing": "Certificates Generating",
            "Completed": "Certificates Generated"
        },
        "orderType": {
            "Institution": "Institution",
            "CertOfSpecialist": "Certificate of specialist",
            "CertForLecturers": "Certificate for lecturers"
        },
        "certificateState": {
            "AwaitingPayment": "Awaiting Payment",
            "Processing": "Certificates Generating",
            "Completed": "Certificates Generated",
            "InQueue": "In queue"
        },
        "certificateType": {
            "IES": "IES",
            "ICI": "ICI",
            "CertOfSpecialist": "Certificate of specialist",
            "CertForLecturers": "Certificate for lecturers"
        },
        "certificateTypeShort": {
            "IES": "IES",
            "ICI": "ICI",
            "CertOfSpecialist": "Spec.",
            "CertForLecturers": "Lect."
        }
    },
    "components": {
        "appForm": {
            "actions": {
                "create": "Create",
                "submit": "Save",
                "nextStep": "Continue",
                "prevStep": "Back",
                "cancel": "Cancel"
            }
        },
        "uploader": {
            "dndText": "Drag and Drop files here",
            "addFile": "Add file",
            "maxFileSize": "Max. file size",
            "validation": {
                "max-file-size": "File '%{file}' is too large",
                "max-total-size": "Maximum files size limit has been reached.",
                "accept": "File type for '%{file}' is not supported",
                "duplicate": "File '%{file}' has already been uploaded"
            }
        },
        "select": {
            "no-result": "No data available"
        },
        "dialog": {
            "confirm": "OK",
            "cancel": "Cancel"
        },
        "datePickerModal": {
            "reset": "reset"
        },
        "filterBar": {
            "hints": {
                "string": {
                    "Like": "has"
                },
                "boolean": {
                    "Eq": "is",
                    "Neq": "is not"
                },
                "enum": {
                    "Eq": "is",
                    "Neq": "is not"
                },
                "date": {
                    "Eq": "is",
                    "Neq": "is not",
                    "Lte": "to",
                    "Gte": "from"
                },
                "number": {
                    "Eq": "equals",
                    "Neq": "not equal",
                    "Lte": "less than or equal",
                    "Gte": "greater than or equal",
                    "Lt": "less than",
                    "Gt": "greater than"
                }
            },
            "options": {
                "boolean": {
                    "true": "Yes",
                    "false": "No"
                }
            },
            "noData": "No results",
            "searchMenuLabel": "Search Text",
            "searchFilterLabel": "Search"
        },
        "filterCard": {
            "actions": {
                "search": "Search",
                "clear": "Clear"
            },
            "noDataPlaceholder": "No Results Found",
            "searchPlaceholder": "Search",
            "additionalFilterLabel": "Filter"
        },
        "langTabs": {
            "modalTitle": "Languages settings",
            "saveLanguages": "Save languages"
        },
        "uploadFile": {
            "title": "Upload file",
            "inputs": {
                "DisplayName": "Name",
                "LanguageCode": "Language",
                "File": "File"
            }
        },
        "table": {
            "noDataLabel": "No records available"
        }
    },
    "auth": {
        "login": {
            "form": {
                "enterLoginMsg": "please enter your login credentials",
                "inputs": {
                    "Login": "Login",
                    "Password": "Password"
                },
                "actions": {
                    "submit": "Log In"
                },
                "errors": {
                    "invalidCredentials": "Invalid login credentials"
                }
            },
            "forgotPassword": "Sent forgotten password?"
        },
        "logOut": {
            "title": "Log Out"
        },
        "forgotPassword": {
            "form": {
                "enterEmailMsg": "Enter the email of the account for which you want to reset the password. You will receive a link to reset your password on this email.",
                "inputs": {
                    "Email": "Email"
                },
                "actions": {
                    "submit": "Send link"
                }
            },
            "goBack": "go back to login"
        },
        "changePassword": {
            "form": {
                "inputs": {
                    "Password": "Password",
                    "PasswordConfirm": "Confirm Password",
                    "OldPassword": "Current Password",
                    "NewPassword": "New Password",
                    "NewPasswordConfirm": "Confirm New Password"
                },
                "actions": {
                    "submit": "Change Password"
                }
            },
            "reset": {
                "changeMsg": "enter a new password to complete the password change",
                "invalidTokenMsg": "the link is no longer valid",
                "submit": "Change Password"
            },
            "set": {
                "changeMsg": "enter a new password to complete setting the password",
                "invalidTokenMsg": "the link is no longer valid",
                "submit": "Set Password"
            },
            "change": {
                "title": "Change Password"
            },
            "goBack": "go back to login",
            "profileTitle": "Change password"
        },
        "passwordRequirements": {
            "title": "The password must contain:",
            "labels": {
                "MinLength": "Minimum length: {0} characters",
                "MixedCase": "At least one uppercase and one lowercase letter",
                "Numbers": "At least one number",
                "Symbols": "At least one symbol"
            }
        },
        "notify": {
            "forgotPasswordEmailSent": "An email with a link to reset your password has been sent successfully",
            "loggedIn": "Logged In",
            "loggedOut": "Logged Out"
        }
    },
    "profile": {
        "labels": {
            "name": "Name",
            "type": "Type",
            "email": "Email",
            "institution": "Institution"
        },
        "actions": {
            "changePassword": "Change Password"
        },
        "notify": {
            "changePasswordSuccess": "Password was changed successfully"
        }
    },
    "dashboard": {
        "title": "Dashboard",
        "tableTitle": {
            "Admin": "New Orders",
            "Approver": "Programs sent for certification",
            "Client": "Orders waiting for payment",
            "Student": "My certificates"
        },
        "actions": {
            "more": "More"
        }
    },
    "admin": {
        "countries": {
            "table": {
                "addPlaceholder": "Add new country",
                "noDataPlaceholder": "No new country is available",
                "columns": {
                    "Code": "Code",
                    "Name": "Name (%{locale})"
                }
            },
            "dialog": {
                "remove": {
                    "title": "Remove country",
                    "message": "Do you really want to remove this country?"
                }
            },
            "form": {
                "title": "Edit country name",
                "inputs": {
                    "Name": "Name (%{locale})"
                }
            }
        },
        "languages": {
            "table": {
                "addPlaceholder": "Add new language",
                "noDataPlaceholder": "No new language is available",
                "columns": {
                    "Code": "Code",
                    "Name": "Name (%{locale})"
                }
            },
            "dialog": {
                "remove": {
                    "title": "Remove language",
                    "message": "Do you really want to remove this language?"
                }
            },
            "form": {
                "title": "Edit language name",
                "inputs": {
                    "Name": "Name (%{locale})"
                }
            }
        },
        "announcements": {
            "actions": {
                "new": "New announcement",
                "edit": "Edit",
                "backToList": "Back to list",
                "detail": "Detail",
                "delete": "Delete"
            },
            "table": {
                "columns": {
                    "Subject": "Subject",
                    "UserTypes": "User types",
                    "VisibleFrom": "Visible from",
                    "VisibleTo": "Visible to",
                    "CreatedAt": "Created at",
                    "CreatedBy": "Created by"
                }
            },
            "prompts": {
                "delete": {
                    "title": "Remove announcement",
                    "message": "Are you sure you want ot remove this announcement?",
                    "cancel": "Cancel",
                    "confirm": "Remove"
                }
            },
            "form": {
                "submit": {
                    "create": "Create announcement",
                    "update": "Update announcement"
                },
                "inputs": {
                    "Subject": "Subject",
                    "Body": "Body",
                    "VisibleFrom": "Visible from",
                    "VisibleTo": "Visible to",
                    "UserTypes": "User types"
                }
            }
        },
        "users": {
            "active": "Active",
            "inactive": "Inactive",
            "overview": {
                "columns": {
                    "FullName": "Full Name",
                    "Login": "Login",
                    "Email": "Email",
                    "Type": "Type",
                    "IsActive": "State"
                },
                "actions": {
                    "new": "New User"
                }
            },
            "detail": {
                "actions": {
                    "edit": "Edit",
                    "resetPassword": "Reset Password"
                }
            },
            "form": {
                "submit": {
                    "create": "Create",
                    "update": "Edit"
                },
                "inputs": {
                    "FirstName": "First Name",
                    "LastName": "Last Name",
                    "Login": "Login",
                    "Email": "Email",
                    "Type": "User Type",
                    "Institution": "Institution"
                }
            },
            "dialog": {
                "resetPassword": {
                    "title": "Reset user password",
                    "message": "Do you really want to reset password for this user?"
                }
            },
            "notify": {
                "resetPassword": "Password has been reset. An email was sent to the user with a link to change the password."
            }
        },
        "files": {
            "sharing": {
                "public": "Shared",
                "private": "Private",
                "title": "Share file",
                "unpublish": "Cancel Sharing",
                "urlCopied": {
                    "success": "Link was has been copied to clipboard",
                    "failure": "Your browser does not support clipboard."
                }
            },
            "actions": {
                "uploadFile": "Upload File",
                "newFolder": "Create folder",
                "rename": "Rename",
                "move": "Move",
                "download": "Download",
                "share": "Share",
                "delete": "Delete"
            },
            "table": {
                "columns": {
                    "Name": "Name",
                    "Size": "Size",
                    "Sharing": "Sharing",
                    "CreatedAt": "Created At",
                    "CreatedBy": "Created By"
                }
            },
            "form": {
                "folder": {
                    "title": {
                        "new": "New Folder",
                        "edit": "Rename Folder"
                    },
                    "inputs": {
                        "Name": "Name"
                    },
                    "submit": {
                        "new": "Create",
                        "edit": "Rename"
                    }
                },
                "file": {
                    "title": {
                        "upload": "Upload File",
                        "rename": "Rename File",
                        "move": "Move File"
                    },
                    "submit": {
                        "upload": "Upload",
                        "rename": "Rename",
                        "move": "Move"
                    },
                    "inputs": {
                        "DisplayName": "Name",
                        "File": "File"
                    }
                }
            },
            "dialog": {
                "delete": {
                    "file": {
                        "title": "Delete File",
                        "message": "Do you really want to delete this file?"
                    },
                    "folder": {
                        "title": "Delete Folder",
                        "message": "Do you really want to delete this folder?"
                    }
                }
            }
        },
        "employers": {
            "institutions": {
                "title": "Select institution",
                "columns": {
                    "Name": "Institution"
                },
                "defaultName": "Default"
            },
            "detail": {
                "title": "Employers List",
                "actions": {
                    "new": "New Employer"
                },
                "columns": {
                    "Name": "Name",
                    "Country": "Country",
                    "Industry": "Industry",
                    "Url": "Web"
                }
            },
            "form": {
                "title": {
                    "create": "Create Employer",
                    "edit": "Edit Employer"
                },
                "inputs": {
                    "Name": "Name",
                    "CountryCode": "Country",
                    "Industry": "Industry",
                    "Url": "Web"
                },
                "submit": {
                    "create": "Create",
                    "edit": "Edit"
                }
            },
            "dialog": {
                "delete": {
                    "title": "Delete employer",
                    "message": "Do you really want to delete this employer?"
                }
            }
        },
        "emailTemplates": {
            "actions": {
                "detail": "Detail",
                "edit": "Edit"
            },
            "overview": {
                "columns": {
                    "name": "Name"
                },
                "name": {
                    "questionnaireStateChangedMail": "Notification of the status of questionnaires",
                    "questionnaireCreatedMail": "New questionnaire",
                    "programCertificationCreatedMail": "New program certification",
                    "questionnaireSubmittedMail": "Confirmation of successful submission of the completed Questionnaire for rating of educational institutions",
                    "questionnaireEvaluatedMail": "Evaluated questionnaire",
                    "programToCertifyCreatedMail": "New program to certify",
                    "orderStateChangedMail": "Order status info"
                }
            },
            "form": {
                "submit": {
                    "update": "Update template"
                },
                "inputs": {
                    "Subject": "Subject",
                    "Body": "Body",
                    "Placeholders": "Placeholders"
                }
            },
            "placeholders": {
                "surveyUrl": "Survey url",
                "programUrl": "Program url",
                "loginUrl": "Login url",
                "login": "Login",
                "resetUrl": "Reset url",
                "OrderUrl": "Order Url",
                "OrderState": "Order State"
            }
        }
    },
    "institutions": {
        "form": {
            "revision": {
                "edit": "Edit revision",
                "create": "Create revision"
            },
            "info": {
                "inputs": {
                    "Name": "Name (line %{line})",
                    "CountryCode": "Institution Country",
                    "PrimaryLanguageCode": "Primary Language",
                    "Rating": "Rating",
                    "Street": "Street",
                    "City": "City",
                    "Zip": "Zip Code",
                    "Type": "Institution Type"
                },
                "submit": {
                    "create": "Create",
                    "edit": "Edit"
                }
            }
        },
        "overview": {
            "columns": {
                "Name": "Name",
                "ActivatedAt": {
                    "eq": "Activated At",
                    "from": "Activated From",
                    "to": "Activated To"
                },
                "ActivatedBy": "Activated By",
                "Country": "Country",
                "Type": "Type",
                "State": "State",
                "Languages": "Languages"
            },
            "actions": {
                "new": "Create Institution"
            }
        },
        "detail": {
            "labels": {
                "Name": "Name",
                "ActivatedAt": "Activated At",
                "ActivatedBy": "Activated By",
                "UpdatedAt": "Updated At",
                "UpdatedBy": "Updated By",
                "Country": "Institution Country",
                "Type": "Institution Type",
                "PrimaryLanguage": "Primary Language",
                "Rating": "Rating",
                "Street": "Street",
                "City": "City",
                "Zip": "Zip Code",
                "ValidFrom": "Valid from"
            },
            "actions": {
                "edit": "Edit Institution",
                "activate": "Activate",
                "deactivate": "Deactivate",
                "createRevision": "Create revision"
            },
            "revisions": {
                "title": "Revisions"
            },
            "programs": {
                "title": "Programs",
                "backToInstitutionDetail": "Back to Institution"
            }
        },
        "dialog": {
            "activate": {
                "title": "Activate Institution",
                "message": "Do you really want to activate this institution?"
            },
            "deactivate": {
                "title": "Deactivate Institution",
                "message": "Do you really want to deactivate this institution?"
            },
            "deleteRevision": {
                "title": "Delete Revision",
                "message": "Do you really want to delete revision '{0}' valid from {1}?"
            }
        },
        "notify": {
            "activate": "Institution was successfully activated",
            "deactivate": "Institution was successfully deactivated",
            "created": "Institution created successfully",
            "createFailed": "Unable to create institution",
            "deleteRevisionSuccess": "Institution revision deleted successfully",
            "createRevisionSuccess": "Institution revision created successfully",
            "editRevisionSuccess": "Institution revision edited successfully"
        }
    },
    "programs": {
        "overview": {
            "columns": {
                "ProgramName": "Name",
                "InstitutionName": "Institution",
                "ValidFrom": "Valid from",
                "ValidTo": "Valid to",
                "Rating": "Rating",
                "CreatedAt": "Created At",
                "Duration": "Duration",
                "Competences": "Competences",
                "CertifiedAt": {
                    "eq": "Certified",
                    "from": "Certified From",
                    "to": "Certified To"
                },
                "State": "State"
            },
            "actions": {
                "new": "New program",
                "exportCertified": "Download list of certified"
            }
        },
        "form": {
            "revision": {
                "edit": "Edit revision",
                "create": "Create revision"
            },
            "info": {
                "inputs": {
                    "ProgramName": "Name (line %{line})",
                    "InstitutionName": "Institution",
                    "Rating": "Rating",
                    "Competences": "Competences",
                    "Duration": "Duration",
                    "ValidFrom": "Valid from",
                    "ValidTo": "Valid to"
                },
                "submit": {
                    "create": "Create",
                    "edit": "Edit"
                }
            },
            "changeState": {
                "inputs": {
                    "Rating": "Rating"
                },
                "submit": "Confirm"
            },
            "note": {
                "inputs": {
                    "Note": "Note"
                },
                "submit": "Insert"
            }
        },
        "detail": {
            "info": {
                "labels": {
                    "ProgramName": "Name",
                    "Institution": "Institution",
                    "Rating": "Rating",
                    "Competences": "Competences",
                    "State": "State",
                    "CreatedAt": "Created At",
                    "CertifiedAt": "Certified At",
                    "ValidFrom": "Valid from",
                    "ValidTo": "Valid to"
                }
            },
            "files": {
                "title": "Files",
                "columns": {
                    "DisplayName": "Name",
                    "LanguageCode": "Language",
                    "Extension": "Extension",
                    "CreatedAt": "Added At",
                    "User": "Added By"
                },
                "noData": "No files uploaded yet"
            },
            "notes": {
                "title": "Notes",
                "noData": "No notes inserted yet",
                "addNote": "Add Note"
            },
            "actions": {
                "edit": "Edit",
                "changeState": "Change State",
                "uploadFile": "Upload File",
                "create_revision": "Create new revision"
            },
            "revisions": {
                "title": "Program revisions"
            }
        },
        "dialog": {
            "deleteFile": {
                "title": "Delete File",
                "message": "Do you really want to remove this file from the program?"
            },
            "deleteNote": {
                "title": "Delete Note",
                "message": "Do you really want to remove this note from the program?"
            },
            "changeState": {
                "title": "Confirm",
                "message": "Do you really want to change the state of the program?"
            },
            "deleteRevision": {
                "title": "Delete Revision",
                "message": "Do you really want to delete revision '{0}' valid from {1}?"
            }
        },
        "changeState": {
            "Inactive": "Deactivate",
            "WaitingForDocuments": "Add documents",
            "SentForCertification": "Send for certification",
            "Certified": "Confirm certification",
            "CertificationRefused": "Refuse certification"
        },
        "notify": {
            "changeProgramStateSuccess": "Program state was changed successfully",
            "deleteRevisionSuccess": "Program revision deleted successfully",
            "created": "Program created successfully",
            "createRevisionSuccess": "Program revision created successfully",
            "editRevisionSuccess": "Program revision edited successfully"
        },
        "info": {
            "misconfigured": {
                "langs": "Some program revisions don't have all languages provided.",
                "langs_single": "Program revision doesn't have all languages provided.",
                "date": "Some program revisions have validity date that is out of institution revisions validity range.",
                "date_single": "Program revision has validity date that is out of institution revisions validity range."
            }
        }
    },
    "orders": {
        "changeState": {
            "AwaitingPayment": "Await Payment",
            "Paid": "Confirm Payment",
            "Pending": "Pending",
            "Processing": "Generate Certificates"
        },
        "overview": {
            "actions": {
                "new": {
                    "title": "New Order",
                    "options": {
                        "Institution": "for institution",
                        "Student": "for student"
                    }
                }
            },
            "columns": {
                "Id": "ID",
                "ProgramName": "Program",
                "InstitutionName": "Institution",
                "State": "State",
                "FinishedAt": {
                    "eq": "Finished At",
                    "from": "Finished From",
                    "to": "Finished To"
                }
            }
        },
        "form": {
            "new": {
                "student": "Student",
                "students": "Students",
                "certificates": "Certificates",
                "summary": "Order Summary",
                "selectLanguage": "Select certificate languages",
                "steps": {
                    "Order": "Order",
                    "Students": "Students",
                    "Certificates": "Certificates",
                    "Summary": "Summary"
                },
                "inputs": {
                    "Type": "Type",
                    "Currency": "Currency",
                    "Price": "Price",
                    "Institution": "Institution",
                    "Program": "Program",
                    "ProgramName": "Program Name",
                    "FinishedAt": "Finished At",
                    "PublishAt": "Publish At",
                    "RecipientEmail": "Recipient Email",
                    "NumOfStudents": "Number of students",
                    "Note": "Note",
                    "PaymentType": "Payment Type",
                    "Competences": "Competences",
                    "Student": {
                        "DegreeBefore": "Degree Before",
                        "FirstName": "First Name",
                        "LastName": "Last Name",
                        "DegreeAfter": "Degree After",
                        "Birthday": "Birthday",
                        "Email": "Email"
                    }
                },
                "labels": {
                    "orderType": "Vyberte typ objednávky",
                    "orderTypeTitle": "Typ objednávky"
                },
                "actions": {
                    "back": "Back",
                    "next": "Continue",
                    "submit": "Order"
                }
            },
            "publishAt": {
                "title": "Order Edit",
                "inputs": {
                    "PublishAt": "Publish At"
                }
            },
            "changeState": {
                "title": "Zadat uhrazení platby",
                "inputs": {
                    "Price": "Price",
                    "Currency": "Currency",
                    "Files": "Files"
                },
                "submit": "Save"
            },
            "columns": {
                "DegreeBefore": "Degree Before",
                "FirstName": "First Name",
                "LastName": "Last Name",
                "DegreeAfter": "Degree After",
                "Birthday": "Birthday",
                "Email": "Email",
                "Languages": "Languages",
                "LanguagesCount": "Total"
            }
        },
        "detail": {
            "order": "Order #%{id}",
            "students": "Students",
            "files": {
                "title": "Invoices",
                "noData": "No invoices have been uploaded yet",
                "columns": {
                    "DisplayName": "Name",
                    "Extension": "Type",
                    "CreatedAt": "Created At",
                    "User": "Created By"
                }
            },
            "labels": {
                "Program": "Program",
                "Institution": "Institution",
                "Notes": "Notes",
                "State": "State",
                "FinishedAt": "Finished At",
                "PublishAt": "Publish At",
                "RecipientEmail": "Recipient Email",
                "PaymentType": "Payment Type",
                "Competences": "Competences",
                "FieldOfActivity": "Competences",
                "Price": "Price"
            },
            "columns": {
                "DegreeBefore": "Degree Before",
                "FirstName": "First Name",
                "LastName": "Last Name",
                "DegreeAfter": "Degree After",
                "Birthday": "Birthday",
                "Email": "Email",
                "Languages": "Languages",
                "LanguagesCount": "Total"
            },
            "actions": {
                "changeState": "Change State",
                "changePublishAt": "Edit"
            },
            "errors": {
                "institutionMissingLanguages": "Institution is missing languages required by certificates: {langs}. You won't be able to generate certificates before filling those languages in the institution settings."
            }
        },
        "dialog": {
            "changeState": {
                "title": "Confirm Change",
                "message": "Do you really want to change state for this order"
            },
            "deleteFile": {
                "title": "Delete Invoice",
                "message": "Do you really want to delete this invoice?"
            }
        },
        "notify": {
            "sentToQueue": "Certificates were successfully sent to queue.",
            "created": "Order created successfully"
        }
    },
    "certificates": {
        "overview": {
            "columns": {
                "Id": "ID",
                "Name": "Name",
                "ProgramName": "Program",
                "InstitutionName": "Instition",
                "State": "State",
                "Language": "Language",
                "Type": "Type",
                "Number": "Certification number",
                "CreatedAt": {
                    "eq": "Created At",
                    "from": "Created From",
                    "to": "Created To"
                },
                "FinishedAt": {
                    "eq": "Finished At",
                    "from": "Finished From",
                    "to": "Finished To"
                }
            }
        },
        "student": {
            "title": "Student Info",
            "certificates": "Certificates",
            "labels": {
                "Name": "Name",
                "Program": "Program",
                "Institution": "Institution",
                "PublishAt": "Publish At",
                "FinishedAt": "Finished At",
                "Birthday": "Birthday",
                "Email": "Email"
            },
            "columns": {
                "Id": "ID",
                "Name": "Name",
                "Language": "Language",
                "State": "State",
                "Number": "Certification number",
                "Type": "Type"
            },
            "actions": {
                "addAllToQueue": "Add to Queue",
                "addToQueue": "Add to Queue"
            }
        },
        "queue": {
            "noData": "Print queue is empty",
            "columns": {
                "Id": "ID",
                "Name": "Name",
                "Language": "Language",
                "InstitutionName": "Institution name",
                "ProgramName": "Program name",
                "CreatedAt": "Created at",
                "FinishedAt": "Finished at",
                "Number": "Certification number",
                "Type": "Type"
            },
            "actions": {
                "print": "Print"
            },
            "tooltips": {
                "removeFromQueue": "Remove from queue",
                "view": "View"
            }
        },
        "detail": {
            "orderTitle": "Order",
            "certificateTitle": "Certificate",
            "labels": {
                "number": "Certificate number",
                "type": "Type",
                "language": "Language",
                "institution": "Institution",
                "institutionRating": "Institution rating",
                "program": "Program",
                "programRating": "Program rating",
                "name": "Name",
                "student": "Student",
                "email": "Email",
                "birthday": "Birthday",
                "state": "State"
            },
            "actions": {
                "orderDetail": "Order detail",
                "downloadCertificate": "Download certificate",
                "addToQueue": "Add to Queue"
            }
        },
        "tooltips": {
            "studentDetail": "Student Detail"
        },
        "dialog": {
            "addToQueueStudent": {
                "title": "Add to Queue",
                "message": "Do you really want to add all student certificates to queue?"
            },
            "addToQueueCert": {
                "title": "Add to Queue",
                "message": "Do you really want to add the certificate to queue?"
            },
            "addToQueueOrder": {
                "title": "Add to Queue",
                "message": "Do you really want to add the order to queue?"
            },
            "removeFromQueueCert": {
                "title": "Remove from Queue",
                "message": "Do you really want to remove this certificate from queue?"
            },
            "printQueue": {
                "title": "Print Queue",
                "message": "Do you really want to print queue of type {type} for language '{lang}'?"
            }
        }
    }
}
